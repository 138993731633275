ul,
ol {
  margin: 20px 0;
  padding-left: 40px;
}

ul {
  list-style-type: square;
}

li {
  margin: 5px 0;
  padding-left: 10px;
  text-align: justify;
  line-height: var(--spacing);
  ul,
  ol {
    margin: 0;
  }
}
